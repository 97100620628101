.container {
  padding: 20px;
}
.header {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #00205b;
  margin-bottom: 20px;
}
.grid-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
}
.title {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
}
.content {
  font-size: 53px;
  letter-spacing: 0px;
  color: #000000;
}
