.menu-box {
  position: absolute;
  width: 4vw;
  background: #00205b;
  height: 100vh;
  color: #fff;
}
.image {
  height: 30px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
  cursor: pointer;
}
.image-box {
  width: 100%;
}
.icon-box {
  font-size: 24px;
  padding: 8px;
}
.icon {
  margin: 0 auto;
  cursor: pointer;
}
.logout {
  color: #000;
  cursor: pointer;
}
