.content-box {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
}
.title {
  font: normal normal normal 12px/26px Manrope;
  color: #2e2e2e;
}
.content {
  font: normal normal 600 14px/24px Manrope;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
}
.box-header {
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 12px;
}
.header-title {
  font-size: 20px;
  margin: 20px 0;
}
.form-container {
  height: 65vh;
  overflow: scroll;
}
.link-container {
  padding: 10px;
  text-align: center;
  background: #fff;
  margin-top: 10px;
  border-radius: 4px;
}
.extra {
  background: #eee;
  padding: 0px 2px;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 12px;
}
