@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Manrope;
}
