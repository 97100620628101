.project-table {
  height: 400px;
  overflow: scroll !important;
}
.project-table tbody tr {
  background: #fff;
}
.icon {
  cursor: pointer;
  position: relative;
  top: 5px;
  left: 4px;
}
