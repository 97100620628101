.title {
  font-size: 14px;
  color: #2e2e2e;
}
.title--bold {
  font-size: 30px;
  color: #00205b;
  font-weight: 600;
  padding-top: 24px;
}
.form--label {
  color: #2e2e2e;
  font-size: 14px;
}
.login--box {
  padding-top: 26px;
}
.workspace--box {
  padding-top: 26px;
}
.Login {
  background: #fff;
}
.signIn--alt {
  margin: 20px 0px;
  color: #aaaaaa !important;
  font-size: 14px !important;
}
.boxShadow {
  box-shadow: 0px 6px 10px #0000000d;
  border: 1px dashed #e8f0ff;
}
.decode-logo {
  width: 200px;
  padding-top: 20px;
}
.user--icon {
  width: 28px;
  height: 28px;
  background-image: url(../../assets/images/login_sprite.svg);
  background-position: 5px 1px;
}
.view-off--icon {
  width: 28px;
  height: 28px;
  background-image: url(../../assets/images/login_sprite.svg);
  background-position: 206px 1px;
}
.view-on--icon {
  width: 28px;
  height: 28px;
  background-image: url(../../assets/images/login_sprite.svg);
  background-position: 75px 1px;
}
.forgot-password--text {
  color: #002f6c !important;
  font-size: 14px;
  font-weight: normal !important;
  margin-top: 4px !important;
}
.password--text {
  color: #00205b !important;
  font-weight: bold;
}
.password--text:focus {
  border: 1px solid #12bbd7 !important;
  box-shadow: 0 0 0 1px #12bbd7 !important;
}
.password--text:focus ~ div .view-on--icon {
  background-position: 162px 1px;
}
.password--text:focus ~ div .view-off--icon {
  background-position: 206px 1px;
  filter: invert(68%) sepia(81%) saturate(2445%) hue-rotate(141deg)
    brightness(93%) contrast(86%) !important;
}
.email--text {
  color: #00205b !important;
  font-weight: bold;
}
.email--text:focus {
  border: 1px solid #12bbd7 !important;
  box-shadow: 0 0 0 1px #12bbd7 !important;
}
.email--text:focus ~ div .user--icon {
  background-position: -38px 1px;
}
input[aria-invalid="true"] ~ div .user--icon {
  background-position: 33px 1px;
  filter: none !important;
}
input[aria-invalid="true"] ~ div .view-on--icon {
  background-position: 33px 1px;
  filter: none !important;
}
input[aria-invalid="true"] ~ div .view-off--icon {
  background-position: 33px 1px;
  filter: none !important;
}
button:hover[disabled] {
  background: transparent linear-gradient(90deg, #64daa1 0%, #00b4e4 100%) 0% 0%
    no-repeat padding-box !important;
}
.forgot-password--box {
  margin-top: 6px;
  text-align: right;
}
.input--workspace:focus {
  border: 1px solid #12bbd7 !important;
  box-shadow: 0 0 0 1px #12bbd7 !important;
}
.login__error {
  color: #f24444;
  margin-top: 2px;
  font-size: 12px;
}
.slide-image {
  margin: 0 auto;
}
