.card-box {
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 10px #0000001a;
  border-radius: 4px;
  cursor: pointer;
  padding: 12px;
}
.card-title {
  text-align: left;
  font: normal normal 600 18px Manrope;
  letter-spacing: 0px;
  color: #00205b;
  display: inline-flex;
  text-decoration: underline;
}
.card-desc {
  text-align: left;
  font: normal normal medium 16px/25px Manrope;
  letter-spacing: 0px;
  color: #6f6f6f;
  margin-top: 20px;
  line-height: 3;
}
.icon {
  cursor: pointer;
  position: relative;
  top: 3px;
  left: 4px;
}
