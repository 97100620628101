.select-box {
  padding: 20px;
  border: 1px solid #e2e2e2;
  background: #fff;
  cursor: pointer;
  min-width: 215px;
}
.select-box:hover {
  border: 1px solid #53b475;
}
.selected {
  border: 1px solid #53b475;
}
.check {
  position: absolute;
  margin-top: -10px;
  margin-left: 155px;
}
.select-img {
  margin: 0 auto;
}
.title {
  font-size: 20px;
  margin: 20px 0;
}
.select-title {
  text-align: center;
  margin: 10px 0px;
  font: normal normal bold 16px/27px Manrope;
}
.form-container {
  height: 65vh;
}
