.header {
  height: 70px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  padding: 18px;
}
.header-title {
  text-align: left;
  font: normal normal bold 18px/40px Manrope;
  letter-spacing: 0px;
  color: #00205b;
}
.project-box {
  padding: 20px;
}
